<template>
    <b-overlay>
        <b-card>
            <div>
                <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm" class="float-right col-sm-2 mb-1"/>
                <b-table striped responsive bordered hover :items="filtered" :fields="fields" :filter="search" :per-page="perPage" :current-page="currentPage" filter-debounce="500">
                    <template slot="top-row" slot-scope="{ fields }">
                        <b-td v-for="field in fields" :key="field.key">
                            <b-form-input v-if="field.key != 'actions'" size="sm" v-model="filters[field.key]" :placeholder="field.label"/>
                        </b-td>
                    </template>
                    <template v-slot:cell(status)="row">
                        <b-badge v-if="row.item.status == 'v planu'" variant="warning">{{row.item.status}}</b-badge>
                        <b-badge v-else variant="danger">{{row.item.status}}</b-badge>
                    </template>
                    <template v-slot:cell(selected)="row">
                        <b-check v-model="row.item.selected"></b-check>
                    </template>
                    <template #cell(actions)="row">
                        <div class="d-flex">
                            <b-button class="mr-1 float-left"  @click="$refs.orderModal.open(row.item)" variant="success"><fa icon="eye"/></b-button>
                            <b-button class="float-right" v-if="row.item.status == 'na čakanju'" @click="addNarocilo(row.item)"><fa icon="plus-square"/></b-button>
                        </div>
                    </template>
                    <template v-slot:cell(date_due)="row">
                        <div v-if="new Date(row.item.date_due).toLocaleTimeString('sl') === '00:00:00'">{{ new Date(row.item.date_due).toLocaleDateString("sl") }}</div>
                        <div v-else>{{ new Date(row.item.date_due).toLocaleDateString("sl") + " " + new Date(row.item.date_due).toLocaleTimeString("sl") }}</div>
                    </template>
                </b-table>
                <b-row>
                    <b-col class="float-left">
                        <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                            <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key" @click="setPerPage(item)">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="7" md="6" class="float-right">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <OrderModal ref="orderModal" />
    </b-overlay>
</template>

<script>
    import OrderModal from '@/views/Narocilo'
    import fuzzysort from 'fuzzysort'
    export default {
        props: ['orders'],
        components: {
            OrderModal
        },
        data() {
            return {
                selectedDate: new Date().toISOString().substr(0, 10),
                dateFormat: { 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' },
                selectedItem: {},
                search:'',
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                selected: {},
                fields: [
                    { key: 'selected', label: 'Izbira', class: 'text-center' },
                    { key: 'id', label: 'ID', sortable: true, class: 'text-center' },
                    { key: 'client', label: 'Stranka', sortable: true, class: 'text-center' },
                    { key: 'date_due', label: 'Zapadlost', sortable: true, class: 'text-center' },
                    { key: 'quantity', label: 'Količina', sortable: true, class: 'text-center' },
                    { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
                    { key: 'material_type.id', label: 'Material šifra', sortable: true, class: 'text-center' },
                    { key: 'material_type.material', label: 'Material naziv', sortable: true, class: 'text-center' },
                    { key: 'actions', label: '' }
                ],
                filters: {'id': '', 'client': '', 'date_added': '', 'date_due': '', 'quantity': '', 'status': '', 'material_type.id': '', 'material_type.material': ''}
            }
        },
        methods: {
            setPerPage(item) {
                this.perPage = item
            },
            flatten(obj, prefix = [], current = {}) {
                if (typeof (obj) === 'object' && obj !== null) {
                    for (const key of Object.keys(obj)) {
                        this.flatten(obj[key], prefix.concat(key), current)
                    }
                } else {
                    current[prefix.join('.')] = obj
                }
                return current
            }
        },
        computed: {
            totalRows() {
                return this.filtered.length
            },
            filtered() {
                const filtered = this.orders.filter(item => {
                    return Object.keys(this.filters).every(key => String(this.flatten(item)[key]).toLowerCase().includes(this.filters[key].toLowerCase()) || fuzzysort.single(this.filters[key].toLowerCase(), String(this.flatten(item)[key]).toLowerCase()) !== null)
                })
                return filtered.length > 0 ? filtered : [{id: '', client: '', date_added: '', date_due: '', quantity: '', status: ''}]
            }
        }
    }
</script>

<style>

</style>
